import React from "react"
import Layout from "../components/layout"

const StepCard = ({ number, title, description }) => (
  <div className="col-span-12 md:col-span-6 lg:col-span-4 bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className="w-12 h-12 bg-green-600 rounded-full flex items-center justify-center text-white font-bold text-xl">
        {number}
      </div>
      <h3 className="ml-4 text-xl font-bold">{title}</h3>
    </div>
    <p className="text-gray-700 leading-relaxed">{description}</p>
  </div>
)

export default function HowItWorks({ location }) {
  const seo = {
    metaTitle: "How It Works | Litigation Friend",
    metaDescription:
      "Learn about our step-by-step process to help you with your employment case",
    url: location.pathname,
  }

  const steps = [
    {
      number: 1,
      title: "The Problem",
      description:
        "Once you contact us, we will carry out an initial assessment of your case either over email or the phone. We aim to let you know asap whether this is something we can take on and even if it isn't, we aim to give you some handy free tips for your case.",
    },
    {
      number: 2,
      title: "The Potential",
      description:
        "Having got to understand your case, next we'll decide how we move forward together. This is not one size fits all. Your options will be based on your individual circumstances and case. Are you leaving? Were you fired? Do you want a lump sum? Do you want to stay and negotiate terms, perhaps? Do you want something other than money?",
    },
    {
      number: 3,
      title: "The Paper Trail",
      description:
        "Next, we'll start to gather all the documents that prove your version of events. That includes your contracts, emails, whatsapp messages, any work policies, handbooks and anything else you think might help your case.",
    },
    {
      number: 4,
      title: "The Prep",
      description:
        "To get you ready to fight/settle your case against your employer – we will provide you a suite of documents to present to your employer. Plus we will give you script and a one on one training session to prepare you for settlement negotiations.",
    },
    {
      number: 5,
      title: "The Pay Off",
      description:
        "Together, we'll take your case step-by-step. Even though you will be representing yourself, Litigation Friend will be behind you, advising and coaching you every step of the way.",
    },
  ]

  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 mb-12 mt-12 lg:px-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10 text-left">
          How It Works
        </h1>

        <div className="grid grid-cols-12 gap-8 mb-16">
          {steps.map(step => (
            <StepCard
              key={step.number}
              number={step.number}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>

        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-8 lg:col-span-6">
            <div className="bg-blue-50 p-8 rounded-lg">
              <p className="text-lg mb-6">
                To get started, all you need to do is contact us and we'll take
                care of the rest.
              </p>
              <a
                href="/contact-us"
                className="inline-block bg-green-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-300"
              >
                Contact Us Today
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
